.inner {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title_box {
  margin-bottom: 60px;
}

.title {
  font-size: 50px;
  font-weight: 700;
}

.form_inner {
  width: 100%;
}

.input_box {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_label {
  font-size: 20px;
  font-weight: 400;
}

.input {
  width: 70%;
  outline: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  padding: 5px;
  box-sizing: border-box;
  &::placeholder {
    font-size: 16px;
    font-weight: 400;
    font-family: Gaegu, sans-serif;
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.button_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  font-family: Gaegu, sans-serif;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  font-size: 36px;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #008d62;
  color: #fff;
}

// 회원가입 페이지 이동

.signup_box {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.signup {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
