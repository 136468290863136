.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.popup_text {
  white-space: nowrap;
  font-size: 18px;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 100px;
  font-weight: 700;
}

.sub_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.71;
  text-align: right;
  color: #333;
}

.sub_title_b {
  font-weight: 700;
  color: #008d62;
}

.title_w {
  color: #f07470;
}

.title_m {
  color: #00498c;
}

.inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile_inner {
  display: flex;
  flex-direction: column;
}

.profile_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.profile_box:last-child {
  margin-bottom: 0;
}

.profile_m {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #00498c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_w {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #f07470;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_number {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

.profile_number_b {
  font-size: 30px;
  font-weight: bold;
}

.profile_name {
  width: 160px;
  font-size: 20px;
  font-weight: 400;
  text-indent: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -10px;
  }
  100% {
    top: 0px;
  }
}

.w_ball_box {
  width: calc(100% - 100px);
  margin-left: 10px;
  position: relative;
}

.m_ball_box {
  width: calc(100% - 100px);
  margin-left: 10px;
  position: relative;
}

.ball_w {
  position: absolute;
  right: 10px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f07470;
  transition: all 0.3s ease-in-out;
  animation: bounce 0.8s ease-in Infinite Alternate;
}

.ball_w:nth-child(2) {
  right: -10px;
  animation-delay: 0.3s;
}

.ball_w:nth-child(3) {
  right: -30px;
  animation-delay: 0.6s;
}

.ball_m {
  position: absolute;
  left: -40px;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #00498c;
  animation: bounce 0.8s ease-in Infinite Alternate;
}

.ball_m:nth-child(2) {
  left: -20px;
  animation-delay: 0.3s;
}

.ball_m:nth-child(3) {
  left: 0;
  animation-delay: 0.6s;
}

.button_box {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.button {
  cursor: pointer;
  width: 100%;
  font-family: Gaegu;
  outline: none;
  border: 0;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 18px;
  background-color: #008d62;
  // background-color: #474747;
  color: #fff;
}

// 헤더

.header_box {
  position: absolute;
  top: 30px;
  width: 100%;
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  box-sizing: border-box;
}

.invite_img {
  cursor: pointer;
  width: 50px;
  height: 40px;
}

.mypage_img {
  cursor: pointer;
  width: 30px;
  height: 40px;
}

// 몰라

.room_box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #333;
  border-radius: 10px;
  background-color: #fff;
  width: 80%;
}

.invite_text {
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

.invite_button_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.invite_button {
  cursor: pointer;
  width: 45%;
  background-color: #00498c;
  border: 1px solid #00498c;
  border-radius: 10px;
  color: #fff;
  padding: 10px 0;
  box-sizing: border-box;
  &:first-child {
    background-color: #f07470;
    border: 1px solid #f07470;
  }
}
