.cat {
  width: 80%;
  overflow: visible;
}

.cat-wrapper {
  width: 180px;
}

.eyes circle {
  fill: #fff;
}

.frontlegs,
.backlegs {
  opacity: 1;
}
