.wrapper {
  max-width: 768px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.inner {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title_box {
  margin-bottom: 60px;
}

.title {
  font-size: 50px;
  font-weight: 700;
}

.form_inner {
  width: 100%;
}

.input_box {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input_label {
  font-size: 20px;
  font-weight: 400;
}

.input {
  width: 70%;
  outline: none;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 400;
  padding: 5px;
  box-sizing: border-box;
}

.input::placeholder {
  font-size: 16px;
  font-weight: 400;
  font-family: Gaegu, sans-serif;
}

.radio_inner {
  width: 70%;
  display: flex;
  align-items: center;
}

.radio_box {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.radio_box:last-child {
  margin-right: 0;
}

.radio_input {
  display: none;
  margin: 0 10px 0 0;
}

.radio_lable {
  font-size: 30px;
  color: #00498c;
  cursor: pointer;
}

.radio_lable_w {
  color: #f07470;
}

input[id='male'] + label,
input[id='woman'] + label {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #000;
  border-radius: 50%;
  margin-right: 10px;
  width: 12px;
  height: 12px;
}

input[id='male'] + label,
input[id='woman'] + label {
  background-color: #fff;
}

input[id='male']:checked + label {
  background-color: #00498c;
}

input[id='woman']:checked + label {
  background-color: #f07470;
}

.button_box {
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  font-family: Gaegu, sans-serif;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  font-size: 36px;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #008d62;
  color: #fff;
}

.signup_box {
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.signup {
  text-decoration: none;
  color: #333;
  font-size: 14px;
  font-weight: 700;
}
