.container {
  height: 100%;
}

.title_box {
  position: relative;
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

.back_nav {
  position: absolute;
  left: 20px;
  color: #333;
}

.title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

.text_box,
.input_box {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.text,
.label {
  color: #333;
  line-height: 1.6;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.label {
  text-align: left;
}

.select {
  outline: none;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
}

.button_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.button {
  cursor: pointer;
  color: #333;
  padding: 5px 20px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #008d62;
    border: 1px solid #008d62;
  }
}

.input {
  outline: none;
  width: 100%;
  color: #333;
  font-size: 14px;
  border: 0;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px;
  box-sizing: border-box;
  &::placeholder {
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

// id 값 있을 때
.account_wrraper {
  width: 100%;
  max-width: 768px;
  padding: 10px;
  box-sizing: border-box;
}

// id 값 없을 때

.account_inner {
  width: 100%;
  max-width: 768px;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
}

.account_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close_button {
  cursor: pointer;
  color: #333;
}

.account_box {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  // border-radius: 10px;
}

.account {
  color: #333;
  font-size: 14px;
  line-height: 1.6;
}

.account_danger {
  color: #ff5360;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.6;
}
