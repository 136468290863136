.wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 20px 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  &::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
  }
}

.title_box {
  width: 100%;
  position: relative;
  margin-bottom: 50px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.button_box {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.button,
.w_button {
  width: 30px;
  height: 30px;
  color: #00498c;
}

.w_button {
  color: #f07470;
}

.inner {
  width: 100%;
}

.record_box {
  margin-bottom: 20px;
}

.record_table {
  margin-top: 10px;
  width: 100%;
  border: 1px solid #333;
  text-align: center;
  border-collapse: collapse;
}

.record_th,
.record_td {
  width: 33%;
  border: 1px solid #333;
  padding: 5px;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 700;
  color: #333;
  vertical-align: middle;
  &:nth-child(2) {
    color: #00498c;
  }
  &:last-child {
    color: #f07470;
  }
}

.record_td {
  font-weight: 500;
}

.menu_inner {
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.menu_box,
.w_menu_box {
  cursor: pointer;
  display: block;
  text-decoration: none;
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #00498c;
  border-radius: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.w_menu_box {
  border: 1px solid #f07470;
}

.menu_text,
.active_menu_text {
  font-weight: 700;
  color: #333;
  line-height: 1.71;
  text-decoration: none;
}

.active_menu_text {
  font-weight: 500;
}

// 로그아웃 추가

.logout_button {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 5px 10px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
  background-color: #fff;
}

// 회원탈퇴 추가

.user_name_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.withdrawal_button {
  cursor: pointer;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 5px 10px;
  box-sizing: border-box;
  background-color: transparent;
}

// 회원탈퇴 물어보는 부분

.modal_bg_box {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}

.confirm_box {
  width: calc(100% - 20px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #008d62;
  border-radius: 10px;
  background-color: #fff;
}

.confirm_text {
  text-align: center;
  color: #333;
  line-height: 1.7;
  font-weight: 400;
  font-size: 15px;
}

.confirm_button_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm_button {
  cursor: pointer;
  width: calc(50% - 10px);
  padding: 10px;
  box-sizing: border-box;
  border: 0;
  background-color: #00498c;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  &:first-child {
    background-color: #f07470;
    margin-left: 0;
    margin-right: 10px;
  }
}
