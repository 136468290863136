.container {
  height: 100%;
}

.title_box {
  position: relative;
  text-align: center;
  padding: 20px 0;
  box-sizing: border-box;
}

.back_nav {
  position: absolute;
  left: 20px;
  color: #333;
}

.title {
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

.table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.thead {
  border-bottom: 3px solid #dcdcdc;
  text-align: center;
}

.tbody {
  border-bottom: 1px solid #dcdcdc;
  text-align: center;
}

.tr {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #008d62;
    > td {
      color: #fff;
    }
  }
}

.th,
.td {
  padding: 10px 5px;
  box-sizing: border-box;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:first-child {
    width: 50px;
  }
}

.title_th {
  width: 35%;
  text-align: left;
}

.button_box {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.button {
  cursor: pointer;
  color: #333;
  padding: 5px 20px;
  box-sizing: border-box;
  background-color: transparent;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #008d62;
    border: 1px solid #008d62;
  }
}
