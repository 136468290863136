.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select_popup {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.select_text {
  white-space: nowrap;
  font-size: 22px;
}

.button_box {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirm_button,
.cancel_button {
  width: 50%;
  padding: 20px 0;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  border: 1px solid #000;
  // background-color: #008d62;
  color: #fff;
  font-size: 18px;
  margin: 0 10px 0 0;
}

.confirm_button {
  margin: 0 0 0 10px;
}

.confirm_button.male,
.cancel_button.male {
  background-color: #00498c;
}

.confirm_button.female,
.cancel_button.female {
  background-color: #f07470;
}

.inner {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.m_select_box,
.w_select_box {
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00498c;
  margin-bottom: 30px;
}

.w_select_box {
  background-color: #f07470;
}

.m_select_box:last-child,
.m_select_box:last-child {
  margin-bottom: 0;
}

.select_number {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.timer_box {
  margin-bottom: 20px;
}

.timer {
  font-weight: 500;
  color: #333;
}

.timer_b {
  font-weight: 700;
  color: #f07470;
}

.modal_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  padding: 20px;
  box-sizing: border-box;
}

.modal_inner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 768px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
}

.modal_text {
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.7;
}
