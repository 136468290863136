.wrraper {
  width: 100%;
  height: 100%;
}

.header_inner {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
}

.header_box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  color: #333;
  font-weight: 500;
  margin-left: 10px;
}

.content_box {
  border-top: 1px solid #dcdcdc;
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.content {
  color: #333;
  font-weight: 500;
  height: 100%;
  overflow: auto;
}
