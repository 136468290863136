.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.timmmer_popup {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.timmer_popup_text {
  font-size: 18px;
  margin-bottom: 20px;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0;
  }
}

.timmer_popup_text_b {
  color: #f07470;
  font-weight: 700;
}

.timmer_popup_button {
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Gaegu;
  color: #fff;
  background-color: #008d62;
}

.timmer_box {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 768px;
  width: 100%;
  padding: 40px 0 10px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timmer {
  color: #000;
  font-size: 18px;
}

.inner {
  width: 100%;
  height: 100%;
  background-color: #9bbbd4;
  padding: 80px 10px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  &::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
  }
}

.your_talk_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.talk_profile_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.m_profile,
.w_profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00498c;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w_profile {
  background-color: #f07470;
}

.profile_number {
  color: #fff;
  font-size: 20px;
}

.nickname {
  color: #000;
  font-size: 20px;
}

.your_talk_text {
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
}

.your_talk_tail {
  position: absolute;
  left: 5px;
  bottom: -14px;
  border-top: 15px solid #fff;
  border-right: 15px solid transparent;
}

.text {
  display: inline-block;
  font-size: 18px;
}

.my_talk_box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.my_talk {
  max-width: 120px;
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  background-color: #fef01b;
  margin-bottom: 30px;
}

.my_talk_tail {
  display: inline-block;
  position: absolute;
  right: 5px;
  bottom: -14px;
  border-top: 15px solid #fef01b;
  border-left: 15px solid transparent;
}

.bottom_text_inner {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.talk_textarea {
  width: calc(100% - 60px);
  resize: none;
  overflow: hidden;
  outline: none;
  border: 0;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.text_button {
  cursor: pointer;
  outline: none;
  width: 60px;
  height: 40px;
  border: 0;
  font-size: 14px;
  background-color: #008d62;
  color: #fff;
}

.add_button_box {
  margin-left: 10px;
}

.add_button {
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid #00498c;
  border-radius: 6px;
  color: #00498c;
}

.timmer_close_box {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.timmer_close {
  cursor: pointer;
}
