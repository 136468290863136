.your_talk_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.talk_profile_box {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.m_profile,
.w_profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00498c;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w_profile {
  background-color: #f07470;
}

.profile_number {
  color: #fff;
  font-size: 20px;
}

.nickname {
  color: #000;
  font-size: 20px;
}

.your_talk_text {
  width: 100%;
  max-width: 650px;
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  margin-bottom: 30px;
}

.your_talk_tail {
  position: absolute;
  left: 5px;
  bottom: -14px;
  border-top: 15px solid #fff;
  border-right: 15px solid transparent;
}

.text {
  display: inline-block;
  font-size: 18px;
  white-space: pre-wrap;
  word-break: break-word;
}

.my_talk_box {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.my_talk {
  width: 100%;
  max-width: 650px;
  display: inline-block;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  background-color: #fef01b;
  margin-bottom: 30px;
}

.my_talk_tail {
  display: inline-block;
  position: absolute;
  right: 5px;
  bottom: -14px;
  border-top: 15px solid #fef01b;
  border-left: 15px solid transparent;
}

.talk_box {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
