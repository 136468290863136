.modal_bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.3);
  z-index: 99999999999;
}

.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 720px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.inner {
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
}

.confirm_text {
  color: #333;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
}

.confirm_text_b {
  font-size: 12px;
  color: #ff5360;
  font-weight: 700;
}

.button {
  cursor: pointer;
  margin: 10px 0;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  color: #333;
  width: calc(50% - 10px);
  padding: 5px 10px;
  box-sizing: border-box;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
    margin-left: 10px;
  }
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.input_box {
  width: 100%;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}

.input {
  outline: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  &::placeholder {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.textarea {
  outline: none;
  width: 100%;
  height: 300px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  &::placeholder {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}
