body {
  font-family: Gaegu, sans-serif;
  overflow: hidden;
}

.cat-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animation-wrapper {
  display: flex;
}

.animation-wrapper.notvisible {
  display: none;
}

.cat {
  fill: #008d62;
  width: 80%;
  overflow: visible;
}

.cat-wrapper {
  width: 180px;
}

.eyes circle {
  fill: #fff;
}

.frontlegs,
.backlegs {
  opacity: 1;
}

.logoani {
  align-self: flex-end;
  margin-left: -25px;
  font-size: 30px;
}

.logo {
  font-size: 55px;
  opacity: 0;
  position: absolute;
  top: -40px;
}

.logo.visible {
  opacity: 1;
  top: 40px;
  transition: all 1s;
}

.wowuser {
  position: absolute;
  bottom: -30px;
  font-size: 16px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
