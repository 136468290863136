.wrraper {
  width: 100%;
  height: 100%;
}

.header_inner {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
}

.header_box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  color: #333;
  font-weight: 500;
  margin-left: 10px;
}

.content_box {
  border-top: 1px solid #dcdcdc;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 110px;
  height: calc(100% - 56px);
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.content {
  color: #333;
  font-weight: 500;
}

.agree_box {
  max-width: 768px;
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
}

.input_box {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  > input,
  > label {
    cursor: pointer;
    color: #333;
    font-weight: 700;
    font-size: 14px;
  }
}

.button {
  cursor: pointer;
  outline: none;
  width: 100%;
  background-color: #fff;
  border: 1px solid #008d62;
  border-radius: 10px;
  padding: 10px 0;
  box-sizing: border-box;
  color: #333;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #008d62;
    color: #fff;
  }
}

.link {
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  color: rgb(35, 54, 151);
}
