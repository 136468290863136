.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.header {
  width: 100%;
  position: absolute;
  top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-sizing: border-box;
}

.invite_code_box {
  cursor: pointer;
}

.invite_code {
  color: #333;
  font-weight: 700;
}

.hide_invite_box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  box-sizing: border-box;
  max-width: calc(768px - 40px);
  width: calc(100% - 40px);
  transition: all 0.3s ease-in-out;
  background-color: #fff;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  text-align: center;
}

// 유저 초대 목록
.inner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inner_box {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.profile_inner {
  display: flex;
  flex-direction: column;
}

.profile_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
}

.profile_box:last-child {
  margin-bottom: 0;
}

.profile_m {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #00498c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_w {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #f07470;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_number {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

.profile_number_b {
  font-size: 30px;
  font-weight: bold;
}

.profile_name {
  width: 160px;
  font-size: 20px;
  font-weight: 400;
  text-indent: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

// 초대 하는 중
.profile_wait_box {
  text-align: center;
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}

.profile_wait {
  font-size: 18px;
  font-weight: 700;
  color: #333;
}

.profile_wait b {
  position: relative;
  top: 10px;
  display: inline-block;
  animation: bounce 0.3s ease infinite alternate;
  font-family: 'Titan One', cursive;
  font-size: 18px;
  color: #333;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
    0 5px 0 #ccc, 0 6px 0 transparent, 0 7px 0 transparent, 0 8px 0 transparent,
    0 9px 0 transparent, 0 10px 10px rgba(0, 0, 0, 0.4);
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    animation-delay: 0.4s;
  }
  &:nth-child(5) {
    animation-delay: 0.5s;
  }
  &:nth-child(6) {
    animation-delay: 0.6s;
  }
  &:nth-child(7) {
    animation-delay: 0.7s;
  }
  // &:nth-child(8) {
  //   animation-delay: 0.7s;
  // }
}

@keyframes bounce {
  100% {
    top: -10px;
    text-shadow: 0 1px 0 #ccc, 0 2px 0 #ccc, 0 3px 0 #ccc, 0 4px 0 #ccc,
      0 5px 0 #ccc, 0 6px 0 #ccc, 0 7px 0 #ccc, 0 8px 0 #ccc, 0 9px 0 #ccc,
      0 50px 25px rgba(0, 0, 0, 0.2);
  }
}

// 버튼

.button_box {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
}

.button {
  cursor: pointer;
  width: 100%;
  font-family: Gaegu;
  outline: none;
  border: 0;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 18px;
  background-color: #008d62;
  color: #fff;
}
