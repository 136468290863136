.container {
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
}

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  padding: 0 20px 20px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.header_inner {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
}

.header_box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  color: #333;
  font-weight: 500;
  margin-left: 10px;
}

.back_icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.title_box {
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.paymnet_box {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  margin-bottom: 10px;
}

.text {
  color: #333;
  font-size: 14px;
  line-height: 1.6;
}
