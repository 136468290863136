.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.hide_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgba($color: #000000, $alpha: 0.85);
}

.ladder_popup {
  width: 90%;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.ladder_text {
  white-space: nowrap;
  font-size: 22px;
  margin-bottom: 30px;
}

.ladder_button {
  outline: none;
  cursor: pointer;
  padding: 20px 0;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 10px;
  background-color: #008d62;
  width: 100%;
  color: #fff;
  font-size: 20px;
}

.inner {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: auto 0;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.inner_box {
  width: 50%;
  &:last-child {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.inner_box + .inner_box {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.result_canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.m_profile_box,
.w_profile_box {
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #00498c;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
  &.w_profile_box {
    background-color: #f07470;
  }
}

.m_profile_box {
  left: 0;
}

.w_profile_box {
  right: 0;
}

.m_profile_box.first {
  top: 0;
}

.m_profile_box.second {
  top: 50%;
  transform: translateY(-50%);
}

.m_profile_box.third {
  bottom: 0;
}

.w_profile_box.first {
  top: 0;
}

.w_profile_box.second {
  top: 50%;
  transform: translateY(-50%);
}

.w_profile_box.third {
  bottom: 0;
}

.profile {
  color: #fff;
}

.nickname {
  color: #fff;
}

// 상단 시간초

.timmer_box {
  max-width: 768px;
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.timmer_text {
  color: #333;
  font-size: 20px;
  font-weight: 700;
}

// 밑에 셀렉트 버튼
.select_box {
  position: fixed;
  bottom: 0;
  max-width: 768px;
  width: 100%;
}

.select_button,
.reload_button {
  cursor: pointer;
  width: 50%;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #00498c;
  color: #fff;
  border: 0;
  &:last-child {
    background-color: #f07470;
  }
}

.reload_button {
  width: 100%;
}

// 버튼추가

.button_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 768px;
  margin-top: 20px;
}

.button_w,
.button_m {
  cursor: pointer;
  outline: none;
  border: 0;
  color: #fff;
  font-weight: 500;
  font-size: 15px;
  width: 50%;
  padding: 10px 20px;
  box-sizing: border-box;
  background-color: #00498c;
  &:last-child {
    background-color: #f07470;
  }
}
