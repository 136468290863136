.container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 720px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 99999999999;
}

.wrapper {
  width: 100%;
  max-width: 720px;
  border: 1px solid #333;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.header_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.cancel_icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.title {
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  color: #333;
}

.title_b {
  color: #008d62;
}

.price_button {
  cursor: pointer;
  width: 100%;
  display: flex;
  align-content: center;
  outline: none;
  cursor: pointer;
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  font-family: Gaegu, sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
  transition: all 0.3s ease-in-out;
  &:hover {
    border: 1px solid #008d62;
    color: #008d62;
  }
}

.button_icon {
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.error_text {
  margin: 10px 0 0 10px;
  color: #ff5360;
  font-size: 14px;
  font-weight: 700;
}

.pay_button {
  opacity: 0;
  visibility: hidden;
}

.blocking {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}
