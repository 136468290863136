// 매칭 잡히기 전 로딩
.centered {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  background: #000;
  // filter: blur(10px) contrast(20);
}

.matching_box {
  position: absolute;
  z-index: 2;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.matching_text {
  color: #fff;
  font-size: 42px;
  font-weight: 700;
  text-align: center;
}

.blob-1,
.blob-2 {
  width: 70px;
  height: 70px;
  position: absolute;
  background: #f07470;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blob-1 {
  left: 20%;
  animation: osc-l 2.5s ease infinite;
}

.blob-2 {
  left: 80%;
  animation: osc-r 2.5s ease infinite;
  background: #00498c;
}

@keyframes osc-l {
  0% {
    left: 20%;
  }
  20% {
    background: #f07470;
  }
  50% {
    left: 50%;
    background: rgb(120, 218, 32);
  }
  60% {
    background: #f07470;
  }
  100% {
    left: 20%;
  }
}

@keyframes osc-r {
  0% {
    left: 80%;
  }
  20% {
    background: #00498c;
  }
  50% {
    left: 50%;
    background: rgb(120, 218, 32);
  }
  60% {
    background: #00498c;
  }
  100% {
    left: 80%;
  }
}

.timmmer_popup {
  max-width: 740px;
  width: 90%;
  position: fixed;
  bottom: 20px;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.timmer_popup_text {
  font-size: 18px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.timmer_popup_text_b {
  font-weight: 700;
}

.timmer_popup_button_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timmer_popup_button {
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 20px;
  font-family: Gaegu;
  color: #fff;
  background-color: #474747;
  margin-right: 10px;
}

.timmer_popup_button:last-child {
  margin-right: 0;
  margin-left: 10px;
  background-color: #008d62;
}

// 시간
.time {
  color: #f07470;
  font-weight: 700;
  margin-bottom: 10px;
}

.time_b {
  color: #00498c;
}

// 참여 인원 관련 css

.participation_status_wrapper {
  z-index: 1;
  max-width: 750px;
  width: 100%;
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #333;
  border-radius: 10px;
  background-color: #fff;
}

.participation_status_title {
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.participation_status_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.participation_status_box {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
}

.participation_status_item_box {
  opacity: 0.5;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
}

.participation_status_item_box.active {
  opacity: 1;
}

.participation_status_m_item,
.participation_status_w_item {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00498c;
  > P {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
  }
}

.participation_status_w_item {
  background-color: #f07470;
}

.participation_status_nickname {
  width: calc(100% - 60px);
  color: #333;
  font-weight: 600;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// 취소했을 때 모달 css

.cancel_box {
  z-index: 1;
  width: 100%;
  max-width: 750px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 10px;
}

.cancel_text {
  text-align: center;
  color: #333;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.7;
}

// 프로그래스 바

.prograss_bar_box {
  position: relative;
  width: 50%;
  height: 10px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid transparent;
  border-radius: 6px;
  background-color: transparent;
}

// animation
@keyframes progressbar {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.prograss_bar {
  position: absolute;
  width: 100%;
  height: 10px;
  left: 0;
  border: 1px solid #f07470;
  border-radius: 6px;
  background-color: #f07470;
  animation: progressbar 3s ease-out;
}

.button_box {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 99999;
  bottom: 0;
}

.button {
  cursor: pointer;
  width: 100%;
  font-family: Gaegu;
  outline: none;
  border: 0;
  padding: 20px 0;
  box-sizing: border-box;
  font-size: 18px;
  background-color: #ececec;
  // background-color: #474747;
  color: #000;
}
