.container {
  width: 100%;
  height: 100%;
}

.header_inner {
  display: inline-block;
  padding: 20px;
  box-sizing: border-box;
}

.header_box {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.text {
  color: #333;
  font-weight: 500;
  margin-left: 10px;
}

.content_box {
  border-top: 1px solid #dcdcdc;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  color: #333;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.input_box {
  width: 100%;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
}

.label {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.7;
}

.input {
  outline: none;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  &::placeholder {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.textarea {
  outline: none;
  width: 100%;
  height: 300px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  &::placeholder {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    transition: all 0.3s ease-in-out;
  }
  &:focus::placeholder {
    color: transparent;
  }
}

.button {
  cursor: pointer;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 0;
  border-radius: 10px;
  background-color: #008d62;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
