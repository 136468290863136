.inner {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.title_box {
  margin-bottom: 40px;
}

.title {
  font-size: 100px;
  font-weight: 700;
}

.sub_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.71;
  text-align: right;
  color: #333;
}

.sub_title_b {
  font-weight: 700;
  color: #008d62;
}

.title_w {
  color: #f07470;
}

.title_m {
  color: #00498c;
}

.button_box {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.button {
  width: 100%;
  font-family: Gaegu, sans-serif;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  font-size: 36px;
  padding: 10px;
  box-sizing: border-box;
  background-color: #008d62;
  color: #fff;
}

.signup_button_box {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
}

.signup_button {
  cursor: pointer;
  outline: none;
  border: 0;
  background-color: transparent;
  font-size: 14px;
  font-weight: 700;
  color: #333;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #f07470;
  }
}
