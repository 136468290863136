.container {
  max-width: 768px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.inner {
  width: 100%;
  height: 100%;
  background-color: #9bbbd4;
  padding: 80px 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  &::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
  }
}

.back_box {
  max-width: 768px;
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 1;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back_icon {
  cursor: pointer;
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
}

.nickname {
  font-size: 14px;
  color: #333;
}

.bottom_text_inner {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.talk_textarea {
  width: calc(100% - 60px);
  resize: none;
  overflow: hidden;
  outline: none;
  border: 0;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.text_button {
  cursor: pointer;
  outline: none;
  width: 60px;
  height: 40px;
  border: 0;
  font-size: 14px;
  background-color: #008d62;
  color: #fff;
}
