.container {
  margin: 20px auto 0;
  max-width: 350px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.arrow_button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  color: #333;
  &:hover {
    color: #ff5360;
  }
}

.button_box {
  width: calc(100% - 50px);
  display: grid;
  place-items: center;
  gap: 0 20px;
  grid-template-columns: repeat(5, 1fr);
}

.button,
.active_button {
  cursor: pointer;
  padding: 5px 10px;
  box-sizing: border-box;
  color: #333;
  background-color: transparent;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #fff;
    background-color: #ff5360;
    border: 1px solid #ff5360;
  }
}

.active_button {
  color: #fff;
  background-color: #ff5360;
  border: 1px solid #ff5360;
}

.page_button {
  background-color: transparent;
  border: 0;
}
