.container {
  max-width: 768px;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
}

.text {
  color: #333;
  font-size: 14px;
  line-height: 1.6;
  font-weight: 500;
}

.button_box {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button,
.confirm_button,
.cancel_button {
  width: 100%;
  font-family: Gaegu, sans-serif;
  cursor: pointer;
  outline: none;
  border: 0;
  border-radius: 10px;
  font-size: 15px;
  padding: 10px 0;
  box-sizing: border-box;
  background-color: #008d62;
  color: #fff;
}

.confirm_button {
  width: 48%;
  background-color: #00498c;
}

.cancel_button {
  width: 48%;
  background-color: #f07470;
}
