.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
}

.time_box {
  max-width: 768px;
  width: 100%;
  z-index: 3;
  text-align: center;
  background-color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
  position: absolute;
  top: 20px;
}

.title_box {
  position: relative;
  z-index: 4;
  margin-bottom: 40px;
}

.title {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  white-space: nowrap;
}

.title_w {
  color: #f07470;
  background-color: #fff;
  border-radius: 10%;
}

.title_m {
  color: #00498c;
  background-color: #fff;
  border-radius: 10%;
}

.inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.inner_box {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.inner_active {
  z-index: 4;
  color: #fff;
}

.profile_inner {
  display: flex;
  flex-direction: column;
}

.hide_inner_box {
  max-width: 768px;
  width: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background-color: rgba($color: #000000, $alpha: 0.7);
}

.profile_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profile_box:last-child {
  margin-bottom: 0;
}

.profile_m {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #00498c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_w {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 0;
  background-color: #f07470;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile_number {
  color: #fff;
  font-size: 24px;
  font-weight: 300;
}

.profile_number_b {
  font-size: 30px;
  font-weight: bold;
}

.profile_name {
  width: calc(100% - 50px);
  font-size: 20px;
  font-weight: 400;
  text-indent: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.button_box {
  max-width: 768px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

.button {
  cursor: pointer;
  outline: none;
  border: 0;
  background-color: #008d62;
  padding: 10px 0;
  box-sizing: border-box;
  width: 100%;
  color: #fff;
  font-family: Gaegu;
  font-size: 20px;
}

.hide_box,
.active_hide_box {
  display: none;
  position: absolute;
  z-index: 5;
  background-color: #9bbbd4;
  width: 100%;
  height: 100%;
  padding: 0px 20px 40px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
}

.active_hide_box {
  display: block;
  // -ms-overflow-style: none;
  // /* IE and Edge */
  // scrollbar-width: none;
  // /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none; /* Chrome, Safari, Opera*/
  // }
  // &::-webkit-scrollbar {
  //   display: none;
  //   /* Chrome, Safari, Opera*/
  // }
}

.close_button_box {
  max-width: 768px;
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding: 40px 0 10px;
  background-color: #9bbbd4;
}

.time {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.close_button {
  display: flex;
  cursor: pointer;
  outline: none;
  border: 0;
  background-color: transparent;
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 500;
}

.talk_box {
  max-width: 768px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.talk_textarea {
  width: calc(100% - 60px);
  resize: none;
  overflow: hidden;
  outline: none;
  border: 0;
  height: 40px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.text_button {
  cursor: pointer;
  outline: none;
  width: 60px;
  height: 40px;
  border: 0;
  font-size: 14px;
  background-color: #008d62;
  color: #fff;
}
